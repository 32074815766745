.Burger {
    position: fixed;
    top: 1rem;
    right: 2rem;

    z-index: 101;

    padding: 1rem;

    transition: opacity .15s linear;
    transition: filter .15s linear;
    transition: border .15s ease-in-out;

    border: 3px solid #fff;
    background-color: #4F6668;

    @media (max-width: 600px) {
        padding: .5rem;
        border: 2px solid #fff;
    }

    &:hover {
        cursor: pointer;
        border: 2px solid #eee;
        .box {
            .inner {
                background-color: #eee;
                &:after, &:before {
                    background-color: #eee;
                }
            }
        }
    }

    &.open {
        border: 1px solid #fff;
        .box {
            .inner {
                transition-delay: .22s;
                background-color: transparent !important;
                &:after {
                    top: 0;
                    transition: top .2s cubic-bezier(.333, 0, .666, .333), transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
                    transform: translate3d(0, 10px, 0) rotate(-45deg);
                }
                &:before {
                    top: 0;
                    transition: top .1s cubic-bezier(.333, 0, .666, .333) .15s, transform .13s cubic-bezier(.215, .61, .355, 1) .22s;
                    transform: translate3d(0, 10px, 0) rotate(45deg);
                }
            }
        }
    }

    .box {
        position: relative;
        width: 30px;
        height: 24px;

        .inner {
            position: absolute;
            width: 30px;
            height: 4px;
            top: 2px;
            transition: background-color 0s linear .13s;
            margin-top: -2px;

            background-color: #ffffff;
            border-radius: 4px;

            &:after, &:before {
                box-sizing: inherit;
                position: absolute;
                width: 30px;
                height: 4px;
                transition-timing-function: ease;
                transition-duration: .15s;
                transition-property: transform;
                border-radius: 4px;
                background-color: #ffffff;
            }

            &:before {
                display: block;
                content: "";
                top: 10px;
                transition: top .1s cubic-bezier(.333, .666, .666, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
            }

            &:after {
                display: block;
                content: "";
                top: 20px;
                transition: top .1s cubic-bezier(.333, .666, .666, 1) .2s, transform .13s cubic-bezier(.55, .055, .675, .19);
            }
        }
    }
}