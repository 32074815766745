.Patron {
    position: relative;
    margin: auto;
    padding: 2rem;
    padding-top: 6rem;

    .container, h1 {
        position: relative;
        z-index: 1;
    }
    .container {
        background-color: rgba(#000, .25);
        border-radius: 1rem;
        padding: 1rem;
        margin: 1rem auto;
    }

    &__Background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        opacity: .2;
        box-shadow: inset 0 0 100px rgba(#000, .75);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
}