.Error404 {
    margin-top: 6rem;
    text-align: center;
    height: 100vh;
    position: relative;

    &__Content {
        position: relative;
        z-index: 1;

        h1 {
            padding-top: 3rem;
            margin: 3rem auto;
        }
        p {
            margin: 3rem auto;
        }
        button {
            margin: 3rem auto;
        }
    }

    &__Background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        z-index: 0;
        opacity: .1;

        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

    }
}