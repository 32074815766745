.Calendar {
    position: relative;
    text-align: center;
    padding-top: 6rem;

    .container {
        max-width: 100vw;
        width: 90%;
        position: relative;
        padding: 1rem;

        .fc-toolbar {
            @media (max-width: 768px) {
                flex-direction: column;
            }
        }

        .fc-unthemed td.fc-today {
            background: #263c3e;
            border-color: #ddd;
        }

        .fc-unthemed .fc-content {
            text-align: center;
        }

        .fc-button-primary {
            background: #263c3e;
            border-color: #263c3e;

            &:hover {
                background: #1a292b;
                border-color: #1a292b;
            }
            &:disabled {
                background: #324f52;
                border-color: #324f52;
            }
        }

        .fc-title {
            font-size: 1rem;
            text-align: center;
        }

        .fc-event {
            border: 1px solid #7c9496;
        }

        .fc-event, .fc-event-dot {
            background: #7c9496;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__Background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        opacity: .2;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
}