.CalendarComp {
    position: relative;

    &__List {
        list-style-type: none;
        display: block;
        margin: auto;
        color: #fff;
        font-weight: 600;
        padding: 1rem;

        @media (max-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
        }

        &__Item {
            border: 3px solid #fff;
            margin: 1rem auto;
            padding: .5rem 1rem;

            display: flex;
            justify-content: center;
            align-items: center;

            &__Date {
                text-align: center;
                margin: auto 5%;
            }

            &__Title {
                text-align: left;
                margin: auto 5%;
            }

            &__Time {
                margin: auto 5%;
            }

            &__Link {
                a {
                    display: block;
                }
                margin: auto 5%;
            }

            @media (max-width: 768px) {
                flex: 1 100vw;
                margin: 1rem;
                flex-wrap: wrap;
                &__Date {
                    text-align: center;
                    margin: 1rem;
                }

                &__Title {
                    text-align: center;
                    margin: auto;
                }

                &__Time {
                    text-align: center;
                    margin: 1rem auto;
                }

                &__Link {
                    a {
                        display: block;
                        margin: auto;
                    }
                }
                span {
                    flex: 1 100%;
                }
                .Divider {
                    display: none;
                }
            }
        }
    }
}