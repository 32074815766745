@import '../../abstracts/responsive';

.CallToAction {

    background-size: auto;
    background-color: #fff;
    background-repeat: repeat;


    // &__Titles {
    //     margin: auto;
    //     text-align: center;

    //     .title1 {
    //         color: #333;
    //     }

    //     .title2 {
    //         color: #333;
    //     }
    // }

    /*
        Mobile Notes:
            Tablet: 2 Columns - Img left, Descriptions right
            Mobile: 1 Column - just Descriptions
    */
    &__Beer {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-align: center;

        font-family: 'Special Elite', cursive;

        h2 {
            color: #000;
            font-size: 5rem;
            font-weight: 600;
            filter: drop-shadow(0 0 10px rgba(#000, .75));
        }

        &__Selection1 {
            margin: 1rem;
            text-align: left;
        }
        &__Image {
            margin: 1rem;
            img {
                max-height: 50vh;
                filter: drop-shadow(0 0 10px rgba(#000, .75));
            }
        }
        &__Selection2 {
            margin: 1rem;
            text-align: right;
        }
    }

    @include respond-to('laptop') {
        &__Beer {
            h2 {
                font-size: 3.5rem;
            }
        }
    }

    @media (max-width: 768px) {
        &__Beer {
            flex-direction: column;

            &__Selection1, &__Selection2 {
                margin: auto 1rem;
                text-align: center;
                line-height: 5rem;
            }
            &__Image {
                margin: auto 1rem;
                img {
                    max-width: 100%;
                }
            }
        }
    }

    @media (max-width: 375px) {
        &__Beer {
            h2 {
                font-size: 3.5rem;
            }
        }
    }

}