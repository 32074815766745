.Media {
    transition: all 250ms ease-in-out;
    margin: 0 2rem;
    padding: 2rem 0;
    background-color: #4E6668;
    a {
        font-size: 1.5rem;
        color: #fff;
        margin: 1rem;
        line-height: 2.5rem;
        transition: all 250ms ease-in-out;
        &:hover {
            color: #ddd;
            cursor: pointer;
        }
    }
}