@import '../../abstracts/responsive';

.Contact {
    text-align: center;
    position: relative;
    padding-top: 6rem;

    .container {
        position: relative;
        z-index: 1;

        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;

        .Contact__Left {
            flex: 1 50vw;
            padding: 1rem;
            img {
                max-width: 100%;
            }
        }
        .Contact__Right {
            flex: 1 50vw;
            .ContactForm {
                padding: 1rem;
            }
        }

    }

    @media (max-width: 768px) {
        .container {
            flex-wrap: wrap;
            .Contact__Left {
                flex: 1 100vw;
                display: flex;
                justify-content: center;
                align-items: center;
                p, img {
                    flex: 1 50vw;
                }
                img {
                    max-width: 50%;
                }
            }
            .Contact__Right {
                flex: 1 100vw;
                .ContactForm {
                    width: 90vw;
                    button[type="submit"] {
                        display: block;
                        margin: auto;
                    }
                }
            }
        }
    }

    @media (max-width: 425px) {
        .container {
            .Contact__Left {
                flex-wrap: wrap;
                img {
                    max-width: 75%;
                }
            }
        }
    }

    &__Background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background-size: cover;
        background-position: center center;
        opacity: .25;
        filter: blur(.1rem);
    }

}