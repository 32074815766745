.Information {

    &__Content {
        .container {
            margin: 2rem auto;
        }
        .btn {
            display: inline-block;
            margin: auto;
            margin-left: 2rem;
            margin-bottom: 2rem;
        }
    }

    @media (max-width: 768px) {
        padding: 1rem;
    }

    @media (max-width: 425px) {
        font-size: 1rem;
    }

}