.Modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.25);
    animation-name: appear;
    animation-duration: 300ms;

    &-Dialog {
        width: 100%;
        max-width: 550px;
        background: white;
        position: relative;
        margin: 0 20px;
        max-height: calc(100vh - 40px);
        text-align: left;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: slide-in;
        animation-duration: 0.5s;
    }

    .RemoveOption {
        position: absolute;
        top: 0;
        left: 0;

        border: none;
        background-color: transparent;
        padding: 1rem;
        color: #222;
        cursor: pointer;
        font-weight: 600;
        // font-size: 1rem;
    }

    &-Close {
        position: absolute;
        top: 0;
        right: 0;

        cursor: pointer;
        padding: 1rem;
        color: #222;
        font-size: 2.5rem;
        font-weight: 600;
    }

    &-Header{
        padding: 1rem;

        border-bottom: 1px solid #dbdbdb;
        justify-content: space-between;
        text-align: center;

        h3 {
            color: #222;
        }
    }

    &-Body {
        overflow: auto;
        text-align: center;
    }

    &-Content {
        padding: 1rem;
        a, p, label {
            color: #222 !important;
        }

        .btn {
            display: block;
            margin: 1rem auto;
        }
    }

    @keyframes appear {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes slide-in {
        from {
            transform: translateY(-150px);
        }
        to {
            transform: translateY(0);
        }
    }
  }









