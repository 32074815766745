.Hours {
    text-align: left;
    color: #fff;
    line-height: 0;
    width: 25rem;
    max-width: 100%;

    @media (max-width: 425px) {
        font-size: 1rem;
    }
}