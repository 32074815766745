// @import '../../abstracts/responsive';

.Landing {

    &__Hours {
        text-align: center;
        color: #fff;
        margin: 2rem 10rem;
        // padding: 1rem;
        border: 3px solid #fff;

        position: relative;
        overflow: hidden;

        @media (max-width: 768px) {
            margin: 2rem 1rem;
        }

        .Hours {
            position: relative;
            z-index: 1;
            margin: auto;
            padding: 1rem;
        }
    }

    &__Specials {
        text-align: center;
        color: #fff;
        margin: 2rem 10rem;
        // padding: 1rem;
        border: 3px solid #fff;

        position: relative;
        overflow: hidden;

        @media (max-width: 768px) {
            margin: 2rem 1rem;
        }

        .Specials {
            margin: auto;
            position: relative;
            z-index: 1;
            padding: 1rem;

        }
    }

    &__Information {
        text-align: center;
        color: #fff;
        margin: 2rem 10rem;
        // padding: 1rem;
        border: 3px solid #fff;

        position: relative;
        overflow: hidden;

        @media (max-width: 768px) {
            margin: 2rem 1rem;
        }

        .Information {
            margin: auto;
            position: relative;
            z-index: 1;
            padding: 1rem;
        }
    }

    &__Calendar {
        position: relative;
        overflow: hidden;

        &__Content {
            text-align: center;
            position: relative;
            z-index: 1;
        }

        .container {
            padding: 2rem;
            text-align: center;
        }

        &__Background {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            opacity: .5;
            // background-image: url('https://images.unsplash.com/photo-1518176258769-f227c798150e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &__Extras {

        &__Content {
            display: flex;
            flex-direction: column;
        }

    }

    &__Location {
        margin: 3rem auto;
        position: relative;

        &__Content {
            position: relative;
            z-index: 1;
            text-align: center;
            padding: 1rem;
        }

        &__Background {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 0;
            opacity: .5;

            // background-image: url('https://images.unsplash.com/photo-1546622891-02c72c1537b6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80');
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
        }

    }

}