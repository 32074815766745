// -----------------------------------------------------------------------------
// This file contains all application-wide Sass responsive functionality.
// -----------------------------------------------------------------------------

@mixin respond-to($breakpoint) {
    @if $breakpoint == "mobile-s" {
      @media (min-width: 0px) and (max-width: 320px) {
        @content;
      }
    }

    @else if $breakpoint == "mobile-m" {
      @media (min-width: 320px) and (max-width: 375px) {
        @content;
      }
    }

    @else if $breakpoint == "mobile-l" {
        @media (min-width: 375px) and (max-width: 425px) {
            @content;
        }
    }

    @else if $breakpoint == "tablet" {
        @media (min-width: 425px) and (max-width: 768px) {
            @content;
        }
    }

    @else if $breakpoint == "laptop" {
        @media (min-width: 768px) and (max-width: 1024px) {
            @content;
        }
    }

    @else if $breakpoint == "laptop-l" {
        @media (min-width: 1024px) and (max-width: 1440px) {
            @content;
        }
    }

  }

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
// $breakpoints: (
    // 'laptop': (min-width: 768px), (max-width: 1024px)
    // 'mobile-s': (min-width: 0px) and (max-width: 320px),
    // 'mobile-m': (max-width: 375px),
    // 'mobile-l': (max-width: 425px),
    // 'tablet': (max-width: 768px),
    // 'laptop': (max-width: 1024px),
    // 'laptop-l': (max-width: 1440px),
    // 'fourk': (max-width: 2560px)

    // 'mobile-m': (max-width: 320px),
    // 'mobile-l': (min-width: 375px),
    // 'tablet': (min-width: 425px),
    // 'laptop': (min-width: 768px),
    // 'laptop-l': (min-width: 1024px),
    // 'fourk': (min-width: 1440px)
// );

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
/// Behavior - smallest => largest heirarchy - base < small
/* Usage
    .App {
        background-color: #800;
        @include respond-to('medium') { background-color: #008; }
    }
*/
// @mixin respond-to($breakpoint) {
//     $raw-query: map-get($breakpoints, $breakpoint);

//     @if $raw-query {
//         $query: if(type-of($raw-query)=='string',
//                 unquote($raw-query),
//                 inspect($raw-query));

//         @media #{$query} {
//             @content;
//         }
//     }

//     @else {
//         @error 'No value found for `#{$breakpoint}`. '
//         +'Please make sure it is defined in `$breakpoints` map.';
//     }
// }