.MenuComp {
    background-repeat: repeat;
    position: relative;
    padding: 2rem;

    @media (max-width: 425px) {
        padding: 2rem 1rem;
    }

    color: #fff;

    &.readable {
        font-family: Arial, Helvetica, sans-serif;
    }
    &.false {
        font-family: 'Fredericka the Great', cursive;
    }

    .container {
        text-align: center;

        .toggle-font-btn {
            background-color: #4F6668;
            border: 2px solid #fff;

            &:hover {
                span {
                    color: #4F6668;
                }
                border: 2px solid #4F6668;
                background-color: #fff;
            }

            span {
                font-size: 1rem;
                font-weight: 600;
            }
        }
    }

}