.BeerItForward {
    position: relative;
    z-index: 1;
    margin: 2rem auto;
    width: 100%;

    &__Content {
        position: relative;
        z-index: 1;
        text-align: center;
        width: 75%;
        margin: auto;
        ul {
            text-align: left;
        }
    }

    &__Background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        opacity: .4;
        box-shadow: inset 0 0 1000px rgba(#000, 1);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        // background-image: url('https://images.unsplash.com/photo-1494887205043-c5f291293cf6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80');
    }

}