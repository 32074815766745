.ContactForm {
    text-align: center;
    &__Form {
        text-align: left;
        margin: auto;

        label {
            color: #fff;
        }

        input, textarea {
            display: block;
            width: 100%;
            margin: 1rem;

            font-family: 'Montserrat', sans-serif;
            border: 2px solid #4E6668;
            background-color: #fff;
            color: #4E6668;
            outline: none;
            padding: 1rem;

            transition: all 200ms ease-in-out;
            &:hover {
                border: 2px solid #fff;
                cursor: default;
            }
        }

        button[type="submit"] {
            font-size: 1rem;
            display: block;
            margin: auto;
        }

        .message {
            transition: all 250ms ease-in-out;
            margin: 1rem auto;
            width: 25%;
            width: fit-content;
            padding: .1rem 2rem;
            border: 2px solid #4E6668;

            opacity: 0;

            &.live {
                opacity: 1;
            }

            &.error {
                background-color: #fff;
                border: 2px solid rgba(red, .75);
                color: rgba(red, .75);
                font-weight: 600;
            }
            &.warning {
                background-color: transparent;
                border: 2px solid rgba(yellow, .75);
                color: rgba(yellow, .75);
                font-weight: 600;
            }
            &.success {
                background-color: rgba(green, .75);
                border: 2px solid #fff;
                color: #fff;
                font-weight: 600;
            }
        }
    }
}