.PricingTable {
    margin: 2rem auto;
    min-width: 25%;

    background-size: cover;
    background-repeat: no-repeat;

    border-spacing: 0px;
    border-collapse: collapse;
    border-radius: 1rem;

    filter: drop-shadow(0 0 10px #000);

    thead {
        font-size: 2rem;
    }

    th, td {
        padding: 1rem;
    }

    tbody {
        .Item {
            td {
                border-bottom: 1px groove #eee;
                span {
                    float: right;
                }
            }
        }
    }

    @media (max-width: 425px) {
        background-size: unset;
        background-repeat: repeat;

        th, td {
            padding: .5rem;
            font-size: 1.25rem;
        }
    }
}