.Navbar {
    position: fixed;
    top: -6rem;
    left: 0;
    right: 0;

    z-index: 100;

    height: 6rem;

    background-color: #4F6668;

    transition: top 500ms cubic-bezier(.4,0,.2,1);
    &.visible {
        top: 0;
        filter: drop-shadow(0 0 10px rgba(#000, .5));
    }

    &__Content {

        .location {
            float: left;
            margin: 1rem 2rem;
            a {
                color: #fff;
                font-family: Montserrat, sans-serif;
                font-size: 1rem;

                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                    color: #eee;
                }
            }
        }

        .logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: transform 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

            &:hover {
                transform: translate(-50%, -50%) scale(1.25);
            }
            img {
                max-width: 64px;
                max-height: 100%;
            }
        }

        @media (max-width: 425px) {
            .location {
                display: none;
            }
            .logo {
                left: 64px;
            }
        }

    }
}