@import "~react-image-gallery/styles/scss/image-gallery.scss";

.Gallery {
    width: 90%;
    padding: 1rem;
    margin: 1rem auto;
    border: 3px solid #fff;

    &__Content {
        position: relative;
        text-align: center;
        width: 75%;
        margin: auto;
    }

}