.Header {
    height: 100vh;
    position: relative;
    z-index: 0;

    &__Content {
        position: absolute;
        left: 2.5%;
        top: 2.5%;
        width: 95%;
        height: 95%;

        background-color: rgba(#4F6668, .25);
        border: 5px solid #fff;
        color: #fff;

        &__Location {
            position: absolute;
            top: 1rem;
            left: 1rem;
            font-size: 1rem;

            a {
                color: #fff;
                text-decoration: none;
                svg {
                    font-size: 1rem;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }

        &__Column {
            width: 100%;
            height: 100%;
            padding: 1rem;

            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            align-content: center;

            .logo {
                width: 5rem;
                max-width: 100%;
            }

            .tagline {
                max-width: 100%;
                max-height: 50%;
            }
        }

        &__Underlay {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            opacity: .5;
            filter: blur(.2rem) grayscale(.25);
        }

        @media (max-width: 425px) {
            &__Column {
                padding-top: 4rem;
            }
        }

    }
}