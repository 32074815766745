// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


/// Regular font family
/// @type List
$text-font-stack: Montserrat,
'Open Sans',
'Helvetica Neue Light',
'Helvetica Neue',
'Helvetica',
'Arial',
sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New',
'DejaVu Sans Mono',
'Bitstream Vera Sans Mono',
'Monaco',
monospace !default;



$brand-color: #4F6668;
$brand-light: #7c9496;
$brand-dark: #263c3e;

/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
// $brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;


/// Container's maximum width
/// @type Length
$max-width: 1180px !default;


/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;