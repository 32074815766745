.BeerTable {
    margin: 2rem auto;

    background-size: cover;
    background-repeat: no-repeat;

    border-spacing: 0px;
    border-collapse: collapse;
    border-radius: 1rem;

    filter: drop-shadow(0 0 10px #000);

    th, td {
        padding: 1rem;
    }

    .Item {
        td[colspan="3"] {
            border-bottom: 1px groove #eee;
        }

        &:last-child {
            td {
                border-bottom: none !important;
            }
        }
    }

    @media (max-width: 425px) {
        background-size: unset;
        background-repeat: repeat;

        th, td {
            padding: .5rem;
            font-size: 1.25rem;
        }
    }
}