.Food {
    position: relative;
    z-index: 1;
    margin: 2rem auto;
    width: 100%;

    &__Content {
        position: relative;
        z-index: 1;
        text-align: center;
        width: 50%;
        margin: auto;
    }

    &__Background {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 0;
        opacity: .5;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        // background-image: url('https://images.unsplash.com/photo-1548867688-231911e4ba3c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80');
    }
}