.Specials {

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    &__1, &__2 {
        padding: 1rem;
    }

    h2, p {
        filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.75));
    }

    .Divider {
        font-size: 5rem;
        padding: 1rem;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        .Divider {
            transform: rotateZ(90deg);
            padding:0;
        }
        .Specials__1, .Specials__2 {
            padding: 0;
        }
    }

    @media (max-width: 425px) {
        font-size: 1rem;
    }
}