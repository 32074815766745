.Footer {
    text-align: center;
    margin: 3rem auto;
    padding: .25rem;


    color: #fff;
    font-size: .9rem;

    img {
        width: 64px;
        transition: all ease-in-out 200ms;
        &:hover {
            filter: drop-shadow(0 0 5px rgba(#000, .5));
        }
    }

    a {
        &:hover {
            color: #eee;
            filter: drop-shadow(0 0 5px rgba(#000, .5));
        }
    }

}