@import '../../../abstracts/responsive';

.Sidebar {
    position: fixed;
    z-index: 100;
    top: 0;
    right: -25vw;

    height: 100vh;
    width: 25vw;

    padding: 1rem;
    padding-top: 5rem;

    background-color: #4F6668;

    overflow-y: scroll;
    overflow-x: hidden;

    nav {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    &::-webkit-scrollbar {
        width: .5rem;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 .25rem rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
        border-radius: .25rem;
    }

    .logo {
        img {
            width: 72px;
            transition: transform 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
            &:hover {
                transform: scale(1.25);
            }
        }
    }

    a {
        font-size: 2rem;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        background-color: #4F6668;
        color: #fff;
        margin-bottom: 4rem;

        @media (max-width: 768px) {
            margin-bottom: 2rem;
        }

        &:active,
        &:hover,
        &:focus,
        &:visited {
            text-decoration: none;
        }

        transition: all 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition-delay: .2s;

        &:hover {
            color: rgb(159, 179, 180);
            font-size: 2.25rem;
            transition-delay: 0s;
        }
    }

    transition: right 200ms ease-out;
    &.open {
        right: 0;
        box-shadow: 0 0 15px 15px rgba(#050505, 0.25);
        transition: right 250ms ease-in;
    }

    @include respond-to('mobile-s') {
        right: -100vw;
        width: 100vw;
        a {
            font-size: 1.5rem;
        }
    }
    @include respond-to('mobile-m') {
        right: -100vw;
        width: 100vw;
        a {
            font-size: 1.5rem;
        }
    }
    @include respond-to('mobile-l') {
        right: -100vw;
        width: 100vw;
        a {
            font-size: 1.5rem;
        }
    }
    @include respond-to('tablet') {
        right: -100vw;
        width: 100vw;
    }

    @include respond-to('laptop') {
        right: -50vw;
        width: 50vw;
    }
    @include respond-to('laptop-l') {
        right: -50vw;
        width: 50vw;
    }
}