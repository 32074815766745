.ScrollToTop {
    position: fixed;
    z-index: 50;
    right: 1rem;
    bottom: -4rem;

    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;

    border: 2px solid #fff;
    color: #fff;
    background-color: #4F6668;
    border-radius: 50%;
    padding: .75rem;

    transition: border 200ms ease-in-out;
    transition: color 200ms ease-in-out;
    transition: background-color 200ms ease-in-out;

    &:hover {
        border: 2px solid #4F6668;
        color: #4F6668;
        background-color: #fff;
        cursor: pointer;
    }

    transition: bottom 500ms cubic-bezier(.4,0,.2,1);
    &.visible {
        bottom: 1rem;
    }
}